import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleSidePage from '../components/title-side-page'
import BlogPostFeatured from '../components/blog-post-featured'
import BlogPostRegular from '../components/blog-post-regular'
import Footer from '../components/footer'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container1">
      <Helmet>
        <title>
          Gateway.fm Blog | Insights on Web3 and Blockchain Technology
        </title>
        <meta
          name="description"
          content="Explore Gateway.fm Blog for expert insights, updates, and in-depth articles on Web3 infrastructure and blockchain technology."
        />
        <meta
          property="og:title"
          content="Gateway.fm Blog | Insights on Web3 and Blockchain Technology"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm Blog for expert insights, updates, and in-depth articles on Web3 infrastructure and blockchain technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/18420dc5-c053-44b2-a874-e0750be6cc51?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name2"></Navigation>
      <div className="blog-container2">
        <div className="blog-container3">
          <TitleSidePage
            text="NEWS, UPDATES &amp; GUIDES"
            rootClassName="title-side-pageroot-class-name"
          ></TitleSidePage>
          <Link to="/blog/from-rpc-to-real-world" className="blog-navlink10">
            <BlogPostFeatured
              tag="Web3"
              date="19 November 2024"
              text={
                <Fragment>
                  <span className="blog-text10">
                    <span>From RPC to Real World</span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/igor-mandrigin-gateway-web3-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name2"
              className="blog-component12"
            ></BlogPostFeatured>
          </Link>
          <div className="blog-row10">
            <Link
              to="/blog/inside-rollups-a-deep-dive-into-web3-scalability-solutions"
              className="blog-navlink11"
            >
              <BlogPostRegular
                date="5 November 2024"
                text={
                  <Fragment>
                    <span className="blog-text13">
                      <span className="blog-text14">
                        Inside Rollups: A Deep Dive into Web3 Scalability
                        Solutions
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/rollups-layer-2-gateway-podcast-1500w.jpg"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name"
                className="blog-component13"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/optimism-superchain-scaling-ethereum-with-op-stack"
              className="blog-navlink12"
            >
              <BlogPostRegular
                date="25 October 2024"
                text={
                  <Fragment>
                    <span className="blog-text16">
                      <span>
                        Optimism Superchain: Scaling Ethereum with OP Stack
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/scaling-ethereum-with-op-stack-gateway-presto-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name1"
                className="blog-component14"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row11">
            <BlogPostRegular
              date="7 October 2024"
              text={
                <Fragment>
                  <span className="blog-text19">
                    <span className="blog-text20">
                      Staking: Accessible DeFi Infrastructure for Web3
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-podcast-staking-1500w.jpg"
              header="Polygon AggLayer: The Future of Blockchain Interoperability"
              rootClassName="blog-post-regularroot-class-name26"
              className="blog-component15"
            ></BlogPostRegular>
            <BlogPostRegular
              date="3 October 2024"
              text={
                <Fragment>
                  <span className="blog-text22">
                    <span>
                      Web3 Evolution: How It Just Became Affordable for Everyone
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-podcast-web3-for-everyone-1500w.jpg"
              header="Let Us Build Your Web3 Infrastructure"
              rootClassName="blog-post-regularroot-class-name27"
              className="blog-component16"
            ></BlogPostRegular>
          </div>
          <div className="blog-row12">
            <Link
              to="/blog/web3-tipping-point-slashing-costs-driving-adoption-launching-your-first-project"
              className="blog-navlink13"
            >
              <BlogPostRegular
                date="1 October 2024"
                text={
                  <Fragment>
                    <span className="blog-text25">
                      <span className="blog-text26">
                        Web3 Tipping Point: Slashing Costs, Driving Adoption and
                        Launching Your First Project
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-podcast-blockchain-infrastructure-1500w.jpg"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name24"
                className="blog-component17"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/stakeway-shaping-the-future-of-web3-finance"
              className="blog-navlink14"
            >
              <BlogPostRegular
                date="25 September 2024"
                text={
                  <Fragment>
                    <span className="blog-text28">
                      <span>Stakeway: Shaping the Future of Finance</span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/stakeway-by-gateway-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name25"
                className="blog-component18"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row13">
            <Link
              to="/blog/partnership-with-lumx-unlocking-new-opportunities-for-blockchain-developers"
              className="blog-navlink15"
            >
              <BlogPostRegular
                date="6 September 2024"
                text={
                  <Fragment>
                    <span className="blog-text31">
                      <span className="blog-text32">
                        Partnership with Lumx: Unlocking New Opportunities for
                        Blockchain Developers
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/lumx-partnership-1500w.jpg"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name22"
                className="blog-component19"
              ></BlogPostRegular>
            </Link>
            <Link to="/blog/enterprise-privacy" className="blog-navlink16">
              <BlogPostRegular
                date="3 September 2024"
                text={
                  <Fragment>
                    <span className="blog-text34">
                      <span>ZK-Rollups for Real-World Applications</span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/zk-rollups-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name23"
                className="blog-component20"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row14">
            <Link to="/blog/cdk-erigon-gatway" className="blog-navlink17">
              <BlogPostRegular
                date="26 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text37">
                      <span className="blog-text38">
                        CDK Erigon | Pushing the boundaries of blockchain
                        infrastructure
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/cdk-erigon-gateway-1500w.png"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name20"
                className="blog-component21"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/truthlink-oracle-gateway"
              className="blog-navlink18"
            >
              <BlogPostRegular
                date="19 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text40">
                      <span>Oracle by Gateway.fm</span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/oracle-gateway-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name21"
                className="blog-component22"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row15">
            <Link to="/blog/gateway-indexer" className="blog-navlink19">
              <BlogPostRegular
                date="15 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text43">Indexer by Gateway.fm</span>
                  </Fragment>
                }
                image="/external/blog-banners/indexer-1500w.jpg"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name18"
                className="blog-component23"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/presto-rollup-as-a-service"
              className="blog-navlink20"
            >
              <BlogPostRegular
                date="13 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text44">
                      <span>Presto | Rollup as a Service</span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-presto-blockchain-rollup-raas-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name19"
                className="blog-component24"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row16">
            <Link
              to="/blog/stakeway-bitcoin-babylon"
              className="blog-navlink21"
            >
              <BlogPostRegular
                date="8 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text47">
                      Staking Bitcoin With Babylon
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/stakeway-babylon-1500w.jpg"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name16"
                className="blog-component25"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/haust-network-partners-with-gateway-fm-to-make-web3-more-user-friendly"
              className="blog-navlink22"
            >
              <BlogPostRegular
                date="1 August 2024"
                text={
                  <Fragment>
                    <span className="blog-text48">
                      <span>
                        Haust Network Partners with Gateway.fm To Make Web3 More
                        User Friendly
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/haust-network-partners-with-gateway-fm-to-make-web3-more-user-friendly-1500w.jpg"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name17"
                className="blog-component26"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row17">
            <Link
              to="/blog/polygon-agglayer-the-future-of-blockchain-interoperability"
              className="blog-navlink23"
            >
              <BlogPostRegular
                date="31 July 2024"
                text={
                  <Fragment>
                    <span className="blog-text51">
                      Polygon AggLayer: The Future of Blockchain
                      Interoperability
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/agglayer-polygon-cdk-gateway-blockchain-1500w.png"
                header="Polygon AggLayer: The Future of Blockchain Interoperability"
                rootClassName="blog-post-regularroot-class-name14"
                className="blog-component27"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/let-us-build-your-web3-infrastructure"
              className="blog-navlink24"
            >
              <BlogPostRegular
                date="29 July 2024"
                text={
                  <Fragment>
                    <span className="blog-text52">
                      Let Us Build Your Web3 Infrastructure
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-stakeway-presto-1500w.png"
                header="Let Us Build Your Web3 Infrastructure"
                rootClassName="blog-post-regularroot-class-name15"
                className="blog-component28"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row18">
            <Link
              to="/blog/powering-l2-blockchain-with-polygon-cdk-and-agglayer"
              className="blog-navlink25"
            >
              <BlogPostRegular
                date="22 July 2024"
                text={
                  <Fragment>
                    <span className="blog-text53">
                      Powering L2 BLockchain For TON Aplication Chain With
                      Polygon CDK &amp; AggLayer
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/powering-l2-blockchain-with-polygon-cdk-and-agglayer-1500w.jpg"
                header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
                rootClassName="blog-post-regularroot-class-name12"
                className="blog-component29"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/what-is-raas-rollup-as-a-service"
              className="blog-navlink26"
            >
              <BlogPostRegular
                date="19 July 2024"
                text={
                  <Fragment>
                    <span className="blog-text54">
                      What is RaaS? Rollup-as-a-Service.
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/what-is-raas-rollup-as-a-service-1500w.jpg"
                header="What is RaaS? Rollup-as-a-Service."
                rootClassName="blog-post-regularroot-class-name13"
                className="blog-component30"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row19">
            <Link
              to="/blog/gateway-witnesschain-mainnet-polygon-cdk-agglayer"
              className="blog-navlink27"
            >
              <BlogPostRegular
                date="2 July 2024"
                text={
                  <Fragment>
                    <span className="blog-text55">
                      Gateway.fm and Witness Chain Enhance Blockchain with
                      Polygon CDK and Mainnet Integration
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-witnesschain-mainnet-polygon-cdk-agglayer-1500w.png"
                header="Gateway.fm and Witness Chain Enhance Blockchain with Polygon CDK and Mainnet Integration"
                rootClassName="blog-post-regularroot-class-name10"
                className="blog-component31"
              ></BlogPostRegular>
            </Link>
            <Link to="/blog/gateway-node-sales" className="blog-navlink28">
              <BlogPostRegular
                date="17 June 2024"
                text={
                  <Fragment>
                    <span className="blog-text56">
                      Node Sales Is A Way To Raise Funds With Tangible Assets
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-node-sale-1500w.png"
                header="Node Sales Is A Way To Raise Funds With Tangible Assets"
                rootClassName="blog-post-regularroot-class-name11"
                className="blog-component32"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-row20">
            <Link
              to="/blog/gateway-wirex-case-study"
              className="blog-navlink29"
            >
              <BlogPostRegular
                date="7th of June 2024"
                text={
                  <Fragment>
                    <span className="blog-text57">
                      A Partnership That Transformed The Crypto-Financial
                      Industry
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-wirex-case-study-1500w.png"
                header="A Partnership That Transformed The Crypto-Financial Industry"
                rootClassName="blog-post-regularroot-class-name8"
                className="blog-component33"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/devnet-testnet-or-mainnet-deployment"
              className="blog-navlink30"
            >
              <BlogPostRegular
                date="15 of May 2024"
                text={
                  <Fragment>
                    <span className="blog-text58">
                      Devnet, Testnet or Mainnet deployment?
                    </span>
                  </Fragment>
                }
                image="/external/sandbox-1500w.png"
                header="Devnet, Testnet or Mainnet deployment?"
                rootClassName="blog-post-regularroot-class-name9"
                className="blog-component34"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container4">
            <Link
              to="/blog/choosing-a-da-solution-third-party-local-or-dacs"
              className="blog-navlink31"
            >
              <BlogPostRegular
                date="13 of May 2024"
                text={
                  <Fragment>
                    <span className="blog-text59">
                      <span>
                        Choosing a DA Solution: Third-Party, Local or DACs?
                      </span>
                    </span>
                  </Fragment>
                }
                className="blog-component35"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/lukso-and-gateway-a-successful-partnership"
              className="blog-navlink32"
            >
              <BlogPostRegular
                date="7th of May 2024"
                text={
                  <Fragment>
                    <span className="blog-text61">
                      LUKSO &amp; Gateway.fm: A Successful Partnership
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gatway-lukso-partnership-1500w.webp"
                header="LUKSO &amp; Gateway.fm: A Successful Partnership"
                className="blog-component36"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container5">
            <Link
              to="/blog/gateway-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar"
              className="blog-navlink33"
            >
              <BlogPostRegular
                date="7th of May 2024"
                text={
                  <Fragment>
                    <span className="blog-text62">
                      Gateway.fm Announces Role in Building Public Soroban RPC
                      Infrastructure for Stellar
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-stellar-partnership-1500w1.webp"
                header="Gateway.fm Announces Role in Building Public Soroban RPC Infrastructure for Stellar"
                className="blog-component37"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/how-is-gpt-protocol-building-a-decentralized-ai-network"
              className="blog-navlink34"
            >
              <BlogPostRegular
                date="3rd of May 2024"
                text={
                  <Fragment>
                    <span className="blog-text63">
                      How is GPT Protocol Building a Decentralized AI Network?
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-gpt-protocol-partnership-1500w.webp"
                header="How is GPT Protocol Building a Decentralized AI Network?"
                className="blog-component38"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container6">
            <Link
              to="/blog/gateway-and-gpt-protocol-join-forces"
              className="blog-navlink35"
            >
              <BlogPostRegular
                date="11th of March 2024"
                text={
                  <Fragment>
                    <span className="blog-text64">
                      Gateway.fm and GPT Protocol Join Forces: Redefining
                      Decentralized AI and Blockchain Integration
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-gpt-protocol-partnership-announcement-1500w.webp"
                header="Gateway.fm and GPT Protocol Join Forces: Redefining Decentralized AI and Blockchain Integration"
                className="blog-component39"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/gateway-and-near-foundation-team-up"
              className="blog-navlink36"
            >
              <BlogPostRegular
                date="7th of March 2024"
                text={
                  <Fragment>
                    <span className="blog-text65">
                      Gateway and NEAR Foundation Team Up for Next-Gen Data
                      Availability
                    </span>
                  </Fragment>
                }
                image="/external/blog-banners/gateway-near-partnership-1500w1.webp"
                header="Gateway and NEAR Foundation Team Up for Next-Gen Data Availability"
                className="blog-component40"
              ></BlogPostRegular>
            </Link>
          </div>
          <Link to="/blog/gateway-wirex-partnership" className="blog-navlink37">
            <BlogPostRegular
              date="5th of February 2024"
              text={
                <Fragment>
                  <span className="blog-text66">
                    Gateway Partnership with Wirex
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-wirex-partnership-announcement-1500w.png"
              header="Gateway Partnership with Wirex"
              className="blog-component41"
            ></BlogPostRegular>
          </Link>
          <div className="blog-row21"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Blog
