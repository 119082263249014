import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './card-description.css'

const CardDescription = (props) => {
  return (
    <div className={`card-description-container ${props.rootClassName} `}>
      <div className="card-description-frame1171275325">
        <div className="card-description-frame1171275345">
          <div className="card-description-frame-icon">
            <img
              alt="ArrowRightI537"
              src="/external/arrowrighti537-cgzd.svg"
              className="card-description-arrow-right"
            />
          </div>
        </div>
        <div className="card-description-frame1171275346">
          <div className="card-description-frame-typography1">
            <span className="card-description-text1 HeadingSmall">
              {props.heading ?? (
                <Fragment>
                  <span className="card-description-text3">Privacy</span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="card-description-frame-typography2">
            <span className="card-description-text2 BodyMedium">
              {props.subtext ?? (
                <Fragment>
                  <span className="card-description-text4">
                    Private like your bank account. Efficient like blockchain.
                    Zero Knowledge technology keeps data confidential
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

CardDescription.defaultProps = {
  heading: undefined,
  subtext: undefined,
  rootClassName: '',
}

CardDescription.propTypes = {
  heading: PropTypes.element,
  subtext: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default CardDescription
