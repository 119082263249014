import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import BlogLUKSOGatewayfmASuccessfulPartnership from './views/blog/blog-lukso-gatewayfm-a-successful-partnership'
import BlogIndexerByGatewayfm from './views/blog/blog-indexer-by-gatewayfm'
import BlogLetUsBuildYourWeb3Infrastructure from './views/blog/blog-let-us-build-your-web3-infrastructure'
import Careers from './views/careers'
import CareerBusinessDeveloper from './views/careers/career-business-developer'
import Web3InfrastructureBlueprintSupplyChain from './views/web3-infrastructure-blueprint-supply-chain'
import NodeSale from './views/node-sale'
import BlogOracleByGatewayfm from './views/blog/blog-oracle-by-gatewayfm'
import Blog from './views/blog'
import BlogGatewayfmAndWitnessChainEnhanceBlockchainWithPolygonCDKAndMainnetIntegration from './views/blog/blog-gatewayfm-and-witness-chain-enhance-blockchain-with-polygon-cdk-and-mainnet-integration'
import Web3InfrastructureBlueprintViralDigitalAsset from './views/web3-infrastructure-blueprint-viral-digital-asset'
import Home from './views/home'
import BlogPartnershipWithLumxUnlockingNewOpportunitiesForBlockchainDevelopers from './views/blog/blog-partnership-with-lumx-unlocking-new-opportunities-for-blockchain-developers'
import ContactForm from './views/contact-form'
import BlogGatewayfmAndNEARFoundationTeamUp from './views/blog/blog-gatewayfm-and-near-foundation-team-up'
import BlogWhatIsRaaSRollupAsAService from './views/blog/blog-what-is-raa-s-rollup-as-a-service'
import Page from './views/page'
import BlogPartnershipWithWirex from './views/blog/blog-partnership-with-wirex'
import Oracle from './views/oracle'
import CareerBusinessDevelopmentMarketingManager from './views/careers/career-business-development-marketing-manager'
import ThankYou from './views/thank-you'
import Web3InfrastructureBlueprintCarbonCredits from './views/web3-infrastructure-blueprint-carbon-credits'
import Web3InfrastructureBlueprintDigitalTwins from './views/web3-infrastructure-blueprint-digital-twins'
import BlogGatewayfmWirexCaseStudy from './views/blog/blog-gatewayfm-wirex-case-study'
import BookACall from './views/book-a-call'
import BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar from './views/blog/blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar'
import BlogDevnetTestnetOrMainnetDeployment from './views/blog/blog-devnet-testnet-or-mainnet-deployment'
import BlogHowIsGPTProtocolBuildingADecentralizedAINetwork from './views/blog/blog-how-is-gpt-protocol-building-a-decentralized-ai-network'
import RPC from './views/rpc'
import Web3InfrastructureBlueprintDecentralisedExchange from './views/web3-infrastructure-blueprint-decentralised-exchange'
import Bounties from './views/bounties'
import Web3TippingPointSlashingCostsDrivingAdoptionAndLaunchingYourFirstProject from './views/blog/web3-tipping-point-slashing-costs-driving-adoption-and-launching-your-first-project'
import StakingAccessibleDeFiInfrastructureForWeb3 from './views/blog/staking-accessible-de-fi-infrastructure-for-web3'
import Web3InfrastructureBlueprintLoyaltyProgram from './views/web3-infrastructure-blueprint-loyalty-program'
import BlogStakingBitcoinWithBabylon from './views/blog/blog-staking-bitcoin-with-babylon'
import BlogGatewayfmPowersTACATONLayer2ProtocolWithPolygonCDKAndAggLayer from './views/blog/blog-gatewayfm-powers-tac-a-ton-layer-2-protocol-with-polygon-cdk-and-agg-layer'
import ArticlePrivacy from './views/blog/article-privacy'
import Web3EvolutionHowItJustBecameAffordableForEveryone from './views/blog/web3-evolution-how-it-just-became-affordable-for-everyone'
import FromRPCToRealWorld from './views/blog/from-rpc-to-real-world'
import Web3InfrastructureBlueprintDAO from './views/web3-infrastructure-blueprint-dao'
import About from './views/about'
import BlogChoosingADASolutionThirdPartyLocalOrDACs from './views/blog/blog-choosing-a-da-solution-third-party-local-or-da-cs'
import PrivacyPolicy from './views/privacy-policy'
import BlogPolygonAggLayerTheFutureOfBlockchainInteroperability from './views/blog/blog-polygon-agg-layer-the-future-of-blockchain-interoperability'
import Web3InfrastructureBlueprintNFTGallery from './views/web3-infrastructure-blueprint-nft-gallery'
import ArticleCompliance from './views/blog/article-compliance'
import Web3InfrastructureBlueprintNFTPlatform from './views/web3-infrastructure-blueprint-nft-platform'
import ArticleEnterprise from './views/blog/article-enterprise'
import OptimismSuperchainScalingEthereumWithOPStack from './views/blog/optimism-superchain-scaling-ethereum-with-op-stack'
import Indexer from './views/indexer'
import InsideRollupsADeepDiveIntoWeb3ScalabilitySolutions from './views/blog/inside-rollups-a-deep-dive-into-web3-scalability-solutions'
import Web3InfrastructureBlueprintAccountingLedger from './views/web3-infrastructure-blueprint-accounting-ledger'
import BlogCDKErigon from './views/blog/blog-cdk-erigon'
import TermsOfService from './views/terms-of-service'
import Presto from './views/presto'
import StakewayShapingTheFutureOfWeb3Finance from './views/blog/stakeway-shaping-the-future-of-web3-finance'
import BlogRedefiningDecentralizedAIAndBlockchainIntegration from './views/blog/blog-redefining-decentralized-ai-and-blockchain-integration'
import BlogHaustNetworkPartnersWithGatewayfmToMakeWeb3MoreUserFriendly from './views/blog/blog-haust-network-partners-with-gatewayfm-to-make-web3-more-user-friendly'
import BlogPrestoRollupAsAService from './views/blog/blog-presto-rollup-as-a-service'
import Web3InfrastructureBlueprintDigitalBanking from './views/web3-infrastructure-blueprint-digital-banking'
import OldHome from './views/old-home'
import BlogAnnouncingOurNodeSales from './views/blog/blog-announcing-our-node-sales'
import BrandGuidelines from './views/brand-guidelines'
import ArticleIdentity from './views/blog/article-identity'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          component={BlogLUKSOGatewayfmASuccessfulPartnership}
          exact
          path="/blog/lukso-and-gateway-a-successful-partnership"
        />
        <Route
          component={BlogIndexerByGatewayfm}
          exact
          path="/blog/gateway-indexer"
        />
        <Route
          component={BlogLetUsBuildYourWeb3Infrastructure}
          exact
          path="/blog/let-us-build-your-web3-infrastructure"
        />
        <Route component={Careers} exact path="/careers" />
        <Route
          component={CareerBusinessDeveloper}
          exact
          path="/careers/business"
        />
        <Route
          component={Web3InfrastructureBlueprintSupplyChain}
          exact
          path="/web3-infrastructure-blueprint-supply-chain"
        />
        <Route component={NodeSale} exact path="/node-sale" />
        <Route
          component={BlogOracleByGatewayfm}
          exact
          path="/blog/truthlink-oracle-gateway"
        />
        <Route component={Blog} exact path="/blog" />
        <Route
          component={
            BlogGatewayfmAndWitnessChainEnhanceBlockchainWithPolygonCDKAndMainnetIntegration
          }
          exact
          path="/blog/gateway-witnesschain-mainnet-polygon-cdk-agglayer"
        />
        <Route
          component={Web3InfrastructureBlueprintViralDigitalAsset}
          exact
          path="/web3-infrastructure-blueprint-viral-digital-asset"
        />
        <Route component={Home} exact path="/" />
        <Route
          component={
            BlogPartnershipWithLumxUnlockingNewOpportunitiesForBlockchainDevelopers
          }
          exact
          path="/blog/partnership-with-lumx-unlocking-new-opportunities-for-blockchain-developers"
        />
        <Route component={ContactForm} exact path="/contact-form" />
        <Route
          component={BlogGatewayfmAndNEARFoundationTeamUp}
          exact
          path="/blog/gateway-and-near-foundation-team-up"
        />
        <Route
          component={BlogWhatIsRaaSRollupAsAService}
          exact
          path="/blog/what-is-raas-rollup-as-a-service"
        />
        <Route component={Page} exact path="/page" />
        <Route
          component={BlogPartnershipWithWirex}
          exact
          path="/blog/gateway-wirex-partnership"
        />
        <Route component={Oracle} exact path="/oracle" />
        <Route
          component={CareerBusinessDevelopmentMarketingManager}
          exact
          path="/careers/business2"
        />
        <Route component={ThankYou} exact path="/thank-you" />
        <Route
          component={Web3InfrastructureBlueprintCarbonCredits}
          exact
          path="/web3-infrastructure-blueprint-carbon-credits"
        />
        <Route
          component={Web3InfrastructureBlueprintDigitalTwins}
          exact
          path="/web3-infrastructure-blueprint-digital-twins"
        />
        <Route
          component={BlogGatewayfmWirexCaseStudy}
          exact
          path="/blog/gateway-wirex-case-study"
        />
        <Route component={BookACall} exact path="/book-a-call" />
        <Route
          component={
            BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar
          }
          exact
          path="/blog/gateway-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar"
        />
        <Route
          component={BlogDevnetTestnetOrMainnetDeployment}
          exact
          path="/blog/devnet-testnet-or-mainnet-deployment"
        />
        <Route
          component={BlogHowIsGPTProtocolBuildingADecentralizedAINetwork}
          exact
          path="/blog/how-is-gpt-protocol-building-a-decentralized-ai-network"
        />
        <Route component={RPC} exact path="/rpc" />
        <Route
          component={Web3InfrastructureBlueprintDecentralisedExchange}
          exact
          path="/web3-infrastructure-blueprint-decentralised-exchange"
        />
        <Route component={Bounties} exact path="/bounties" />
        <Route
          component={
            Web3TippingPointSlashingCostsDrivingAdoptionAndLaunchingYourFirstProject
          }
          exact
          path="/blog/web3-tipping-point-slashing-costs-driving-adoption-launching-your-first-project"
        />
        <Route
          component={StakingAccessibleDeFiInfrastructureForWeb3}
          exact
          path="/blog/staking-accessible-defi-infrastructure-for-web3"
        />
        <Route
          component={Web3InfrastructureBlueprintLoyaltyProgram}
          exact
          path="/web3-infrastructure-blueprint-loyalty-program"
        />
        <Route
          component={BlogStakingBitcoinWithBabylon}
          exact
          path="/blog/stakeway-bitcoin-babylon"
        />
        <Route
          component={
            BlogGatewayfmPowersTACATONLayer2ProtocolWithPolygonCDKAndAggLayer
          }
          exact
          path="/blog/powering-l2-blockchain-with-polygon-cdk-and-agglayer"
        />
        <Route
          component={ArticlePrivacy}
          exact
          path="/blog/enterprise-privacy"
        />
        <Route
          component={Web3EvolutionHowItJustBecameAffordableForEveryone}
          exact
          path="/blog/web3-evolution-how-it-just-became-affordable-for-everyone"
        />
        <Route
          component={FromRPCToRealWorld}
          exact
          path="/blog/from-rpc-to-real-world"
        />
        <Route
          component={Web3InfrastructureBlueprintDAO}
          exact
          path="/web3-infrastructure-blueprint-dao"
        />
        <Route component={About} exact path="/about" />
        <Route
          component={BlogChoosingADASolutionThirdPartyLocalOrDACs}
          exact
          path="/blog/choosing-a-da-solution-third-party-local-or-dacs"
        />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route
          component={BlogPolygonAggLayerTheFutureOfBlockchainInteroperability}
          exact
          path="/blog/polygon-agglayer-the-future-of-blockchain-interoperability"
        />
        <Route
          component={Web3InfrastructureBlueprintNFTGallery}
          exact
          path="/web3-infrastructure-blueprint-nft-gallery"
        />
        <Route
          component={ArticleCompliance}
          exact
          path="/blog/zk-rollups-for-real-world-applications3"
        />
        <Route
          component={Web3InfrastructureBlueprintNFTPlatform}
          exact
          path="/web3-infrastructure-blueprint-nft-platform"
        />
        <Route
          component={ArticleEnterprise}
          exact
          path="/blog/enterprise-blockchain"
        />
        <Route
          component={OptimismSuperchainScalingEthereumWithOPStack}
          exact
          path="/blog/optimism-superchain-scaling-ethereum-with-op-stack"
        />
        <Route component={Indexer} exact path="/indexer" />
        <Route
          component={InsideRollupsADeepDiveIntoWeb3ScalabilitySolutions}
          exact
          path="/blog/inside-rollups-a-deep-dive-into-web3-scalability-solutions"
        />
        <Route
          component={Web3InfrastructureBlueprintAccountingLedger}
          exact
          path="/web3-infrastructure-blueprint-accounting-ledger"
        />
        <Route component={BlogCDKErigon} exact path="/blog/cdk-erigon-gatway" />
        <Route component={TermsOfService} exact path="/terms-of-service" />
        <Route component={Presto} exact path="/presto" />
        <Route
          component={StakewayShapingTheFutureOfWeb3Finance}
          exact
          path="/blog/stakeway-shaping-the-future-of-web3-finance"
        />
        <Route
          component={BlogRedefiningDecentralizedAIAndBlockchainIntegration}
          exact
          path="/blog/gateway-and-gpt-protocol-join-forces"
        />
        <Route
          component={
            BlogHaustNetworkPartnersWithGatewayfmToMakeWeb3MoreUserFriendly
          }
          exact
          path="/blog/haust-network-partners-with-gateway-fm-to-make-web3-more-user-friendly"
        />
        <Route
          component={BlogPrestoRollupAsAService}
          exact
          path="/blog/presto-rollup-as-a-service"
        />
        <Route
          component={Web3InfrastructureBlueprintDigitalBanking}
          exact
          path="/web3-infrastructure-blueprint-digital-banking"
        />
        <Route component={OldHome} exact path="/old-home" />
        <Route
          component={BlogAnnouncingOurNodeSales}
          exact
          path="/blog/gateway-node-sales"
        />
        <Route component={BrandGuidelines} exact path="/brand-guidelines" />
        <Route
          component={ArticleIdentity}
          exact
          path="/blog/enterprise-identity-security"
        />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
