import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import CardDescription from '../components/card-description'
import CardDescriptionCompact from '../components/card-description-compact'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>
          Gateway.fm :: Blockchain infrastructure for your Web3 project
        </title>
        <meta
          property="og:title"
          content="Gateway.fm :: Blockchain infrastructure for your Web3 project"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name64"></Navigation>
      <div className="home-hero-space">
        <div className="home-alert">
          <div className="home-container2">
            <div className="home-institution-logo-assets1">
              <div className="home-linea11">
                <div className="home-clippathgroup1">
                  <div className="home-clip016424701">
                    <img
                      alt="VectorI861"
                      src="/external/vectori861-el4j.svg"
                      className="home-vector100"
                    />
                  </div>
                  <div className="home-group10">
                    <img
                      alt="VectorI861"
                      src="/external/vectori861-diqa.svg"
                      className="home-vector101"
                    />
                    <img
                      alt="VectorI861"
                      src="/external/vectori861-r1n3.svg"
                      className="home-vector102"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="home-institution-logo-assets2">
              <div className="home-arbitrumarblogo11">
                <div className="home-layerx002011">
                  <div className="home-frame24055884772321">
                    <div className="home-group11">
                      <div className="home-group12">
                        <img
                          alt="VectorI108"
                          src="/external/vectori108-vzb.svg"
                          className="home-vector103"
                        />
                        <div className="home-group13">
                          <div className="home-group14">
                            <div className="home-group15">
                              <img
                                alt="VectorI108"
                                src="/external/vectori108-fzwa.svg"
                                className="home-vector104"
                              />
                            </div>
                            <div className="home-group16">
                              <img
                                alt="VectorI108"
                                src="/external/vectori108-bbqi.svg"
                                className="home-vector105"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="home-group17">
                          <img
                            alt="VectorI108"
                            src="/external/vectori108-bqtl.svg"
                            className="home-vector106"
                          />
                        </div>
                        <img
                          alt="VectorI108"
                          src="/external/vectori108-gn3.svg"
                          className="home-vector107"
                        />
                        <div className="home-group18">
                          <img
                            alt="VectorI108"
                            src="/external/vectori108-ofko.svg"
                            className="home-vector108"
                          />
                          <img
                            alt="VectorI108"
                            src="/external/vectori108-4e6rk.svg"
                            className="home-vector109"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="home-text100">
            Linea and Arbitrum rollups are here
          </span>
          <a
            href="https://presto.gateway.fm/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-frame-button10"
          >
            <span className="home-text101 ActionSmall">Deploy now</span>
            <div className="home-right-icon1">
              <img
                alt="ArrowRightI861"
                src="/external/arrowrighti861-wkb3.svg"
                className="home-arrow-right1"
              />
            </div>
          </a>
        </div>
        <div className="home-headline">
          <div className="home-content1">
            <div className="home-container3">
              <span className="home-text102">
                <span className="home-text103">Your on-chain</span>
                <br></br>
              </span>
              <span id="phrase-swap" className="home-text105">
                 
              </span>
            </div>
            <div className="home-frame481591">
              <span className="home-text106">
                Complete blockchain infrastructure for your project, with tech
                advisory and native product ecosystem
              </span>
            </div>
            <div className="home-frame4815971">
              <Link to="/book-a-call" className="home-frame-button11">
                <div className="home-left-icon1">
                  <img
                    alt="CalendarI861"
                    src="/external/calendari861-qc79.svg"
                    className="home-calendar1"
                  />
                </div>
                <span className="home-text107 ActionLarge">Book a demo</span>
              </Link>
              <a
                href="https://presto.gateway.fm"
                className="home-frame-button12"
              >
                <span className="home-text108 ActionLarge">Deploy now</span>
                <div className="home-right-icon2">
                  <img
                    alt="ArrowRightI861"
                    src="/external/arrowrighti861-dqyr.svg"
                    className="home-arrow-right2"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-privacy">
        <div className="home-content2">
          <div className="home-chip1">
            <div className="home-left-icon2">
              <img
                alt="LockI536"
                src="/external/locki536-7ik.svg"
                className="home-lock"
              />
            </div>
            <div className="home-label-container1">
              <span className="home-text109">Secured by ZK tech</span>
            </div>
          </div>
          <div className="home-frame-typography10">
            <span className="home-text110 HeadingXLarge">
              <span>Enterprise grade</span>
              <br></br>
              <span>privacy &amp; security</span>
            </span>
          </div>
          <div className="home-frame-typography11">
            <span className="home-text114 BodyMedium">
              <span>Your on-chain solution is reinforced with ZK tech, </span>
              <span>
                a cutting edge advancement offering bank grade security
              </span>
            </span>
          </div>
          <div className="home-frame4815972">
            <Link
              to="/blog/enterprise-blockchain"
              className="home-frame-button13"
            >
              <span className="home-text117 ActionMedium">Learn more</span>
            </Link>
            <a
              href="https://presto.gateway.fm/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-frame-button14"
            >
              <span className="home-text118 ActionMedium">Deploy chain</span>
              <div className="home-right-icon3">
                <img
                  alt="ArrowRightI536"
                  src="/external/arrowrighti536-x3p.svg"
                  className="home-arrow-right3"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          alt="image"
          src="/external/images/security-1200w.png"
          className="home-image1"
        />
      </div>
      <div className="home-enterprise-cards1">
        <Link to="/blog/enterprise-privacy" className="home-navlink1">
          <CardDescription
            heading={
              <Fragment>
                <span className="home-text119">Privacy</span>
              </Fragment>
            }
            subtext={
              <Fragment>
                <span className="home-text120">
                  Private like your bank account. Efficient like blockchain.
                  Zero Knowledge technology keeps data confidential
                </span>
              </Fragment>
            }
            rootClassName="card-descriptionroot-class-name3"
            className="home-component2"
          ></CardDescription>
        </Link>
        <Link to="/blog/enterprise-identity-security" className="home-navlink2">
          <CardDescription
            heading={
              <Fragment>
                <span className="home-text121">Identity</span>
              </Fragment>
            }
            subtext={
              <Fragment>
                <span className="home-text122">
                  Multi-layered security that exceeds banking standards.
                  Cryptographic protection with tamper-proof verification at
                  every level.
                </span>
              </Fragment>
            }
            rootClassName="card-descriptionroot-class-name4"
            className="home-component3"
          ></CardDescription>
        </Link>
        <Link
          to="/blog/zk-rollups-for-real-world-applications3"
          className="home-navlink3"
        >
          <CardDescription
            heading={
              <Fragment>
                <span className="home-text123">Compliance</span>
              </Fragment>
            }
            subtext={
              <Fragment>
                <span className="home-text124">
                  Enterprise-ready compliance with complete audit trails.
                  Meeting the strictest regulatory requirements while enabling
                  innovation.
                </span>
              </Fragment>
            }
            rootClassName="card-descriptionroot-class-name5"
            className="home-component4"
          ></CardDescription>
        </Link>
      </div>
      <div className="home-excellence">
        <div className="home-content3">
          <div className="home-chip2">
            <div className="home-left-icon3">
              <img
                alt="LightningI539"
                src="/external/lightningi539-v9s.svg"
                className="home-lightning"
              />
            </div>
            <div className="home-label-container2">
              <span className="home-text125">Powered by CDK Erigon</span>
            </div>
          </div>
          <div className="home-frame-typography12">
            <span className="home-text126 HeadingXLarge">
              <span>Technical</span>
              <br></br>
              <span>excellence</span>
            </span>
          </div>
          <div className="home-frame481494">
            <img
              alt="logopolygon5390"
              src="/external/logopolygon5390-dfp.svg"
              className="home-logopolygon1"
            />
            <div className="home-logolinea1">
              <div className="home-linea12">
                <div className="home-clippathgroup2">
                  <div className="home-clip016424702">
                    <img
                      alt="VectorI539"
                      src="/external/vectori539-11jr.svg"
                      className="home-vector110"
                    />
                  </div>
                  <div className="home-group19">
                    <img
                      alt="VectorI539"
                      src="/external/vectori539-zial.svg"
                      className="home-vector111"
                    />
                    <img
                      alt="VectorI539"
                      src="/external/vectori539-dyx.svg"
                      className="home-vector112"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="logooptimism5391"
              src="/external/logooptimism5391-ul6m.svg"
              className="home-logooptimism1"
            />
            <div className="home-logoarbitrum1">
              <div className="home-arbitrumarblogo12">
                <div className="home-layerx002012">
                  <div className="home-frame24055884772322">
                    <div className="home-group20">
                      <div className="home-group21">
                        <img
                          alt="VectorI539"
                          src="/external/vectori539-2yhj.svg"
                          className="home-vector113"
                        />
                        <div className="home-group22">
                          <div className="home-group23">
                            <div className="home-group24">
                              <img
                                alt="VectorI539"
                                src="/external/vectori539-9dah.svg"
                                className="home-vector114"
                              />
                            </div>
                            <div className="home-group25">
                              <img
                                alt="VectorI539"
                                src="/external/vectori539-aooc.svg"
                                className="home-vector115"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="home-group26">
                          <img
                            alt="VectorI539"
                            src="/external/vectori539-pyjl.svg"
                            className="home-vector116"
                          />
                        </div>
                        <img
                          alt="VectorI539"
                          src="/external/vectori539-1h96.svg"
                          className="home-vector117"
                        />
                        <div className="home-group27">
                          <img
                            alt="VectorI539"
                            src="/external/vectori539-ni3b.svg"
                            className="home-vector118"
                          />
                          <img
                            alt="VectorI539"
                            src="/external/vectori539-sfkzg.svg"
                            className="home-vector119"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-frame-typography13">
            <span className="home-text130 BodyMedium">
              <span>We’re core contributors to major tech stacks. </span>
              <span>Choose ZK or Optimistic type</span>
            </span>
          </div>
          <div className="home-frame4815973">
            <Link to="/book-a-call" className="home-frame-button15">
              <span className="home-text133 ActionMedium">Free advisory</span>
            </Link>
            <a
              href="https://presto.gateway.fm/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-frame-button16"
            >
              <span className="home-text134 ActionMedium">Deploy chain</span>
              <div className="home-right-icon4">
                <img
                  alt="ArrowRightI539"
                  src="/external/arrowrighti539-bpaj.svg"
                  className="home-arrow-right4"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          alt="image"
          src="/external/images/speed1-1200w.png"
          className="home-image2"
        />
      </div>
      <div className="home-enterprise-cards2">
        <CardDescriptionCompact
          heading={
            <Fragment>
              <span className="home-text135">100M+</span>
            </Fragment>
          }
          subtext={
            <Fragment>
              <span className="home-text136">
                Up to 1’300 transactions per second enables over 100M daily
                transactions
              </span>
            </Fragment>
          }
          rootClassName="card-description-compactroot-class-name2"
        ></CardDescriptionCompact>
        <CardDescriptionCompact
          heading={
            <Fragment>
              <span className="home-text137">99.9% SLA</span>
            </Fragment>
          }
          subtext={
            <Fragment>
              <span className="home-text138">
                High standard service level agreement. 24/7 support
              </span>
            </Fragment>
          }
          rootClassName="card-description-compactroot-class-name1"
        ></CardDescriptionCompact>
        <CardDescriptionCompact
          heading={
            <Fragment>
              <span className="home-text139">Lowering cost</span>
            </Fragment>
          }
          subtext={
            <Fragment>
              <span className="home-text140">
                As we make the tech more cost-efficient, your infrastructure
                price will be going down
              </span>
            </Fragment>
          }
          rootClassName="card-description-compactroot-class-name"
        ></CardDescriptionCompact>
      </div>
      <div className="home-native-ecosystem">
        <div className="home-container4">
          <div className="home-frame-typography14">
            <span className="home-text141 HeadingXLarge">
              <span>Native</span>
              <br></br>
              <span>ecosystem</span>
            </span>
          </div>
          <div className="home-frame-typography15">
            <span className="home-text145 BodyMedium">
              <span>Gateway offers complete blockchain ecosystem</span>
              <br></br>
              <span>to maximise your product uptime</span>
            </span>
          </div>
        </div>
        <div className="home-frame1171275357">
          <div className="home-frame1171275349">
            <div className="home-frame1171275302">
              <div className="home-frame1171275347">
                <div className="home-frame-typography16">
                  <span className="home-text149 HeadingSmall">Rollup</span>
                </div>
                <div className="home-frame-typography17">
                  <span className="home-text150 BodyMedium">
                    <span>Your network ready in</span>
                    <br></br>
                    <span>3 clicks</span>
                  </span>
                </div>
                <div className="home-frame4815974">
                  <a
                    href="https://presto.gateway.fm"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-frame-button17"
                  >
                    <span className="home-text154 ActionMedium">Try now</span>
                    <div className="home-right-icon5">
                      <img
                        alt="ArrowRightI861"
                        src="/external/arrowrighti861-6vpt.svg"
                        className="home-arrow-right5"
                      />
                    </div>
                  </a>
                  <Link to="/presto" className="home-frame-button18">
                    <span className="home-text155 ActionMedium">
                      Learn more
                    </span>
                  </Link>
                </div>
              </div>
              <img
                alt="image8618"
                src="/external/image8618-aa3p-500h.png"
                className="home-image3"
              />
            </div>
            <div className="home-frame1171275333">
              <div className="home-frame1171275351">
                <div className="home-frame-typography18">
                  <span className="home-text156 HeadingSmall">RPC</span>
                </div>
                <div className="home-frame-typography19">
                  <span className="home-text157 BodyMedium">
                    <span>Native public RPC infrastructure</span>
                    <br></br>
                    <span>will save up to $150’000 annually</span>
                  </span>
                </div>
                <div className="home-frame4815975">
                  <a
                    href="https://admin.gateway.fm/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-frame-button19"
                  >
                    <span className="home-text161 ActionMedium">Try now</span>
                    <div className="home-right-icon6">
                      <img
                        alt="ArrowRightI108"
                        src="/external/arrowrighti108-5fw7.svg"
                        className="home-arrow-right6"
                      />
                    </div>
                  </a>
                  <Link to="/rpc" className="home-frame-button20">
                    <span className="home-text162 ActionMedium">
                      Learn more
                    </span>
                  </Link>
                </div>
              </div>
              <div className="home-frame11712753361">
                <img
                  alt="image"
                  src="/external/images/rpc1-1500w.png"
                  className="home-image4"
                />
              </div>
            </div>
          </div>
          <div className="home-frame1171275352">
            <div className="home-frame1171275332">
              <div className="home-frame1171275348">
                <div className="home-frame-typography20">
                  <span className="home-text163 HeadingSmall">Indexer</span>
                </div>
                <div className="home-frame-typography21">
                  <span className="home-text164 BodyMedium">
                    <span>Instant time to market,</span>
                    <br></br>
                    <span>any load</span>
                  </span>
                </div>
                <div className="home-frame4815976">
                  <button className="home-frame-button21">
                    <span className="home-text168 ActionMedium">
                      Learn more
                    </span>
                    <div className="home-right-icon7">
                      <img
                        alt="ArrowRightI861"
                        src="/external/arrowrighti861-50go.svg"
                        className="home-arrow-right7"
                      />
                    </div>
                  </button>
                  <Link to="/book-a-call" className="home-frame-button22">
                    <div className="home-left-icon4">
                      <img
                        alt="CalendarI861"
                        src="/external/calendari861-mfbp.svg"
                        className="home-calendar2"
                      />
                    </div>
                    <span className="home-text169 ActionMedium">
                      Book a demo
                    </span>
                  </Link>
                </div>
              </div>
              <div className="home-group481567">
                <img
                  alt="image"
                  src="/external/images/indexer-image-1500h.png"
                  className="home-image5"
                />
              </div>
            </div>
            <div className="home-frame1171275334">
              <div className="home-frame1171275350">
                <div className="home-frame-typography22">
                  <span className="home-text170 HeadingSmall">Oracle</span>
                </div>
                <div className="home-frame-typography23">
                  <span className="home-text171 BodyMedium">
                    <span>Instant time to market,</span>
                    <br></br>
                    <span>any load</span>
                  </span>
                </div>
                <div className="home-frame4815977">
                  <button className="home-frame-button23">
                    <span className="home-text175 ActionMedium">
                      Learn more
                    </span>
                    <div className="home-right-icon8">
                      <img
                        alt="ArrowRightI861"
                        src="/external/arrowrighti861-50go.svg"
                        className="home-arrow-right8"
                      />
                    </div>
                  </button>
                  <Link to="/book-a-call" className="home-frame-button24">
                    <div className="home-left-icon5">
                      <img
                        alt="CalendarI861"
                        src="/external/calendari861-mfbp.svg"
                        className="home-calendar3"
                      />
                    </div>
                    <span className="home-text176 ActionMedium">
                      Book a demo
                    </span>
                  </Link>
                </div>
              </div>
              <div className="home-frame11712753351">
                <img
                  alt="image"
                  src="/external/images/oracle-1500w.png"
                  className="home-image6"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="home-frame1171275338">
          <div className="home-frame-typography24">
            <span className="home-text177 HeadingSmall">Integrations</span>
          </div>
          <div className="home-frame-typography25">
            <span className="home-text178 BodyMedium">
              <span>Customise your network with</span>
              <br></br>
              <span>35+ Gateway partner integrations</span>
            </span>
          </div>
          <div className="home-frame4815978">
            <a
              href="https://presto.gateway.fm/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-frame-button25"
            >
              <span className="home-text182 ActionMedium">Deploy a rollup</span>
              <div className="home-right-icon9">
                <img
                  alt="ArrowRightI994"
                  src="/external/arrowrighti994-4j5p.svg"
                  className="home-arrow-right9"
                />
              </div>
            </a>
            <Link to="/book-a-call" className="home-frame-button26 button">
              <div className="home-left-icon6">
                <img
                  alt="CalendarI861"
                  src="/external/calendari861-rvuk.svg"
                  className="home-calendar4"
                />
              </div>
              <span className="home-text183 ActionMedium">Book a demo</span>
            </Link>
          </div>
          <div className="home-frame2">
            <div className="home-logogoldsky">
              <div className="home-clippathgroup3">
                <div className="home-clip025012199">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-j9e.svg"
                    className="home-vector120"
                  />
                </div>
                <div className="home-group28">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-21xo.svg"
                    className="home-vector121"
                  />
                  <div className="home-maskgroup1">
                    <div className="home-group29">
                      <img
                        alt="VectorI751"
                        src="/external/vectori751-qgyj.svg"
                        className="home-vector122"
                      />
                    </div>
                    <div className="home-group30">
                      <img
                        alt="VectorI751"
                        src="/external/vectori751-wspc.svg"
                        className="home-vector123"
                      />
                      <div className="home-group31">
                        <img
                          alt="VectorI751"
                          src="/external/vectori751-fpl.svg"
                          className="home-vector124"
                        />
                      </div>
                      <img
                        alt="VectorI751"
                        src="/external/vectori751-z47i.svg"
                        className="home-vector125"
                      />
                      <div className="home-group32">
                        <img
                          alt="VectorI751"
                          src="/external/vectori751-wr8.svg"
                          className="home-vector126"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-logothegraph">
              <div className="home-symbols">
                <div className="home-group33">
                  <img
                    alt="Fill19I751"
                    src="/external/fill19i751-h0iu.svg"
                    className="home-fill19"
                  />
                </div>
              </div>
            </div>
            <img
              alt="logoblockscout7510"
              src="/external/logoblockscout7510-3ypf.svg"
              className="home-logoblockscout"
            />
            <img
              alt="logodora7510"
              src="/external/logodora7510-en9yv.svg"
              className="home-logodora1"
            />
            <img
              alt="logoredstone7510"
              src="/external/logoredstone7510-dfne.svg"
              className="home-logoredstone"
            />
            <div className="home-logoapi3">
              <div className="home-layerx002013">
                <div className="home-frame2806123167536">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-99xl.svg"
                    className="home-vector127"
                  />
                  <div className="home-group34">
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-6so8.svg"
                      className="home-vector128"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-6nws.svg"
                      className="home-vector129"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-61gg.svg"
                      className="home-vector130"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-e1kd.svg"
                      className="home-vector131"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-9tj.svg"
                      className="home-vector132"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-dj8d.svg"
                      className="home-vector133"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-tkh9.svg"
                      className="home-vector134"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-04de.svg"
                      className="home-vector135"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-hvk.svg"
                      className="home-vector136"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-w6ls.svg"
                      className="home-vector137"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-gndw.svg"
                      className="home-vector138"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-3txx.svg"
                      className="home-vector139"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-l0f7.svg"
                      className="home-vector140"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-lzw5.svg"
                      className="home-vector141"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-1uw5.svg"
                      className="home-vector142"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-vs7s.svg"
                      className="home-vector143"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-sud.svg"
                      className="home-vector144"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-auaq.svg"
                      className="home-vector145"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-jykg.svg"
                      className="home-vector146"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-5op.svg"
                      className="home-vector147"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-p8zo.svg"
                      className="home-vector148"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-7ylc.svg"
                      className="home-vector149"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-6vti.svg"
                      className="home-vector150"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-v6v6h.svg"
                      className="home-vector151"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-nubm.svg"
                      className="home-vector152"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-9jqt.svg"
                      className="home-vector153"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="logoaxelar7510"
              src="/external/logoaxelar7510-gc39.svg"
              className="home-logoaxelar"
            />
            <div className="home-logoaws">
              <div className="home-amazon-web-services-logo1">
                <div className="home-group35">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-8llv.svg"
                    className="home-vector154"
                  />
                  <div className="home-group36">
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-tnao.svg"
                      className="home-vector155"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-lpqj.svg"
                      className="home-vector156"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="home-logogooglecloudgcp">
              <img
                alt="Frame8I751"
                src="/external/frame8i751-z4y.svg"
                className="home-frame8"
              />
            </div>
            <img
              alt="logomicrosoftazure7510"
              src="/external/logomicrosoftazure7510-tung.svg"
              className="home-logomicrosoftazure"
            />
            <img
              alt="logobaremetal7510"
              src="/external/logobaremetal7510-btnq.svg"
              className="home-logobaremetal"
            />
            <div className="home-logobtqzkproofs">
              <div className="home-maskgroup2">
                <div className="home-group37">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-tywq.svg"
                    className="home-vector157"
                  />
                </div>
                <div className="home-group38">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-dy1t.svg"
                    className="home-vector158"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-mj9u.svg"
                    className="home-vector159"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-nr35.svg"
                    className="home-vector160"
                  />
                </div>
              </div>
            </div>
            <div className="home-logogevulot">
              <img
                alt="gevulotgcolor1I751"
                src="/external/gevulotgcolor1i751-k39.svg"
                className="home-gevulotgcolor1"
              />
            </div>
            <img
              alt="logonear7510"
              src="/external/logonear7510-gwqc.svg"
              className="home-logonear"
            />
            <img
              alt="logocelestia7510"
              src="/external/logocelestia7510-6ml.svg"
              className="home-logocelestia"
            />
            <div className="home-logoavail">
              <img
                alt="avail1I751"
                src="/external/avail1i751-tng2-200h-200h.png"
                className="home-avail1"
              />
            </div>
            <img
              alt="logomagiclink7511"
              src="/external/logomagiclink7511-3wlj.svg"
              className="home-logomagiclink"
            />
            <img
              alt="logometakeep7511"
              src="/external/logometakeep7511-wdc.svg"
              className="home-logometakeep"
            />
            <div className="home-logometamask">
              <img
                alt="Frame481577I751"
                src="/external/frame481577i751-tlhi.svg"
                className="home-frame481577"
              />
            </div>
            <div className="home-logohardhat">
              <div className="home-hardhatseeklogocom1">
                <div className="home-group39">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-vsvo.svg"
                    className="home-vector161"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-t81j.svg"
                    className="home-vector162"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-wbdh.svg"
                    className="home-vector163"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-dv9.svg"
                    className="home-vector164"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-qph.svg"
                    className="home-vector165"
                  />
                  <div className="home-group40">
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-s3gk.svg"
                      className="home-vector166"
                    />
                  </div>
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-58c.svg"
                    className="home-vector167"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-906k.svg"
                    className="home-vector168"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-0hi.svg"
                    className="home-vector169"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-z1ue.svg"
                    className="home-vector170"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-5bvo.svg"
                    className="home-vector171"
                  />
                </div>
              </div>
            </div>
            <img
              alt="logofractalid7511"
              src="/external/logofractalid7511-x97.svg"
              className="home-logofractalid"
            />
            <div className="home-logogatenox">
              <img
                alt="z50vLHlz400x40021I751"
                src="/external/z50vlhlz400x40021i751-q8jl-200h-200h.png"
                className="home-z50v-l-hlz400x40021"
              />
            </div>
            <img
              alt="logopolygon7511"
              src="/external/logopolygon7511-p96r.svg"
              className="home-logopolygon2"
            />
            <div className="home-logoethereum">
              <div className="home-clippathgroup4">
                <div className="home-clip0194206">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-78rj.svg"
                    className="home-vector172"
                  />
                </div>
                <div className="home-group41">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-zwh.svg"
                    className="home-vector173"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-py2t.svg"
                    className="home-vector174"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-q8kf.svg"
                    className="home-vector175"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-bjyy.svg"
                    className="home-vector176"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-2yxa.svg"
                    className="home-vector177"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-3k1c.svg"
                    className="home-vector178"
                  />
                </div>
              </div>
            </div>
            <img
              alt="logooptimism7511"
              src="/external/logooptimism7511-u68o.svg"
              className="home-logooptimism2"
            />
            <div className="home-logolinea2">
              <div className="home-linea13">
                <div className="home-clippathgroup5">
                  <div className="home-clip016424703">
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-bcxu.svg"
                      className="home-vector179"
                    />
                  </div>
                  <div className="home-group42">
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-ix7h.svg"
                      className="home-vector180"
                    />
                    <img
                      alt="VectorI751"
                      src="/external/vectori751-h90q.svg"
                      className="home-vector181"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="home-logozkevm">
              <img
                alt="Frame481579I751"
                src="/external/frame481579i751-sjxi.svg"
                className="home-frame481579"
              />
            </div>
            <img
              alt="logohalliday7511"
              src="/external/logohalliday7511-7qqn.svg"
              className="home-logohalliday"
            />
            <img
              alt="logo3commas7511"
              src="/external/logo3commas7511-bbii.svg"
              className="home-logo3commas"
            />
            <img
              alt="logosimplehash7511"
              src="/external/logosimplehash7511-s4owl.svg"
              className="home-logosimplehash"
            />
            <div className="home-logosafe">
              <img
                alt="Frame481581I751"
                src="/external/frame481581i751-muto.svg"
                className="home-frame481581"
              />
            </div>
            <img
              alt="logobiconomy7511"
              src="/external/logobiconomy7511-2x8h.svg"
              className="home-logobiconomy"
            />
            <div className="home-logosub7">
              <div className="home-maskgroup3">
                <img
                  alt="QIxcOqYERmwC85RhvBu4yYHYOU1I751"
                  src="/external/qixcoqyermwc85rhvbu4yyhyou1i751-32rr-200h-200h.png"
                  className="home-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1"
                />
                <img
                  alt="Rectangle3690I751"
                  src="/external/rectangle3690i751-80nf-200w-200w.png"
                  className="home-rectangle3690"
                />
              </div>
            </div>
            <div className="home-logodomicon">
              <img
                alt="domicon1I751"
                src="/external/domicon1i751-b5g-200h-200h.png"
                className="home-domicon1"
              />
            </div>
            <img
              alt="logogateway7511"
              src="/external/logogateway7511-6xth.svg"
              className="home-logogateway"
            />
            <img
              alt="logoamlbot7511"
              src="/external/logoamlbot7511-lwuj.svg"
              className="home-logoamlbot"
            />
            <div className="home-logoden">
              <div className="home-maskgroup4">
                <img
                  alt="den1I751"
                  src="/external/den1i751-gqu-200w-200w.png"
                  className="home-den1"
                />
              </div>
            </div>
            <div className="home-logoquantstamp">
              <div className="home-group2465">
                <img
                  alt="Vector12I751"
                  src="/external/vector12i751-lssg.svg"
                  className="home-vector12"
                />
              </div>
            </div>
            <div className="home-logooxorio">
              <img
                alt="Frame481583I751"
                src="/external/frame481583i751-3jr9.svg"
                className="home-frame481583"
              />
            </div>
            <div className="home-logoshieldifysecurity">
              <div className="home-layer12">
                <img
                  alt="VectorI751"
                  src="/external/vectori751-wp09.svg"
                  className="home-vector182"
                />
                <img
                  alt="VectorStrokeI751"
                  src="/external/vectorstrokei751-7nfb.svg"
                  className="home-vector-stroke1"
                />
                <img
                  alt="VectorI751"
                  src="/external/vectori751-6nt.svg"
                  className="home-vector183"
                />
                <img
                  alt="VectorStrokeI751"
                  src="/external/vectorstrokei751-11kr.svg"
                  className="home-vector-stroke2"
                />
                <img
                  alt="VectorI751"
                  src="/external/vectori751-91l.svg"
                  className="home-vector184"
                />
                <img
                  alt="VectorI751"
                  src="/external/vectori751-yrsk.svg"
                  className="home-vector185"
                />
              </div>
            </div>
            <div className="home-logoradius">
              <div className="home-layer11">
                <div className="home-group43">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-rrcp.svg"
                    className="home-vector186"
                  />
                  <img
                    alt="Vector2I751"
                    src="/external/vector2i751-g59.svg"
                    className="home-vector21"
                  />
                  <img
                    alt="Vector3I751"
                    src="/external/vector3i751-bjng.svg"
                    className="home-vector3"
                  />
                  <img
                    alt="Vector4I751"
                    src="/external/vector4i751-995f.svg"
                    className="home-vector4"
                  />
                </div>
              </div>
            </div>
            <div className="home-logohorizon">
              <img
                alt="Frame481584I751"
                src="/external/frame481584i751-0ajq.svg"
                className="home-frame481584"
              />
            </div>
            <img
              alt="logostellar7511"
              src="/external/logostellar7511-okoj.svg"
              className="home-logostellar"
            />
            <img
              alt="logopolygoncdk7511"
              src="/external/logopolygoncdk7511-nxpn.svg"
              className="home-logopolygoncdk"
            />
            <div className="home-logozksync">
              <div className="home-group2466">
                <img
                  alt="VectorI751"
                  src="/external/vectori751-brmw.svg"
                  className="home-vector187"
                />
                <img
                  alt="VectorI751"
                  src="/external/vectori751-qxt.svg"
                  className="home-vector188"
                />
              </div>
            </div>
            <div className="home-logoimmutable">
              <img
                alt="SVG646557ee455c3e16e4a9bf82zkEVMicon1I751"
                src="/external/svg646557ee455c3e16e4a9bf82zkevmicon1i751-ap1d.svg"
                className="home-svg646557ee455c3e16e4a9bf82zk-ev-micon1"
              />
            </div>
            <div className="home-logoarbitrum2">
              <div className="home-arbitrumarblogo13">
                <div className="home-layerx002014">
                  <div className="home-frame24055884772323">
                    <div className="home-group44">
                      <div className="home-group45">
                        <img
                          alt="VectorI751"
                          src="/external/vectori751-l6iq.svg"
                          className="home-vector189"
                        />
                        <div className="home-group46">
                          <div className="home-group47">
                            <div className="home-group48">
                              <img
                                alt="VectorI751"
                                src="/external/vectori751-clu8.svg"
                                className="home-vector190"
                              />
                            </div>
                            <div className="home-group49">
                              <img
                                alt="VectorI751"
                                src="/external/vectori751-0yhd.svg"
                                className="home-vector191"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="home-group50">
                          <img
                            alt="VectorI751"
                            src="/external/vectori751-pgue.svg"
                            className="home-vector192"
                          />
                        </div>
                        <img
                          alt="VectorI751"
                          src="/external/vectori751-2sgt.svg"
                          className="home-vector193"
                        />
                        <div className="home-group51">
                          <img
                            alt="VectorI751"
                            src="/external/vectori751-7z6.svg"
                            className="home-vector194"
                          />
                          <img
                            alt="VectorI751"
                            src="/external/vectori751-ohvk.svg"
                            className="home-vector195"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-logohorizen1">
              <div className="home-layer21">
                <div className="home-layer13">
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-ocpr.svg"
                    className="home-vector196"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-qo45.svg"
                    className="home-vector197"
                  />
                  <img
                    alt="VectorI751"
                    src="/external/vectori751-ekz4.svg"
                    className="home-vector198"
                  />
                </div>
              </div>
            </div>
            <img
              alt="logosindri7511"
              src="/external/logosindri7511-l0y9.svg"
              className="home-logosindri"
            />
            <img
              alt="logolayerzero7511"
              src="/external/logolayerzero7511-arsp.svg"
              className="home-logolayerzero"
            />
            <div className="home-logoeigenlayer">
              <div className="home-frame481585">
                <img
                  alt="Vector1I751"
                  src="/external/vector1i751-g3b.svg"
                  className="home-vector1"
                />
                <img
                  alt="Vector2I751"
                  src="/external/vector2i751-dfco.svg"
                  className="home-vector22"
                />
                <img
                  alt="Rectangle3692I751"
                  src="/external/rectangle3692i751-lg4-200w-200w.png"
                  className="home-rectangle3692"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-mentions">
        <div className="home-frame-typography26">
          <span className="home-text184 HeadingXLarge">Mentions</span>
        </div>
        <div className="home-tweets">
          <div className="home-frame1171275353">
            <div className="home-frame1171275327">
              <div className="home-frame117127533710">
                <div className="home-logopolygon3">
                  <img
                    alt="logopolygonI937"
                    src="/external/logopolygoni937-fjrb.svg"
                    className="home-logopolygon4"
                  />
                </div>
                <div className="home-frame-typography27">
                  <span className="home-text185 HeadingXSmall">Polygon</span>
                </div>
              </div>
              <div className="home-frame-typography28">
                <span className="home-text186 BodyMedium">
                  Copy-pasting your code — yes it’s that easy. Scaling is
                  smoother than teflon with EVM-equivalence. @gateway_eth was
                  able to port their public RPC in six minutes
                </span>
              </div>
            </div>
            <div className="home-frame11712753362">
              <div className="home-frame117127533711">
                <img
                  alt="GPT9439"
                  src="/external/gpt9439-ij9.svg"
                  className="home-gpt"
                />
                <div className="home-frame-typography29">
                  <span className="home-text187 HeadingXSmall">GPT</span>
                </div>
              </div>
              <div className="home-frame-typography30">
                <span className="home-text188 BodyMedium">
                  Great job team! Amazing and dedicated individuals. No wonder
                  they are #1
                </span>
              </div>
            </div>
            <div className="home-frame1171275339">
              <div className="home-frame117127533712">
                <div className="home-logodora2">
                  <img
                    alt="logodoraI918"
                    src="/external/logodorai918-2qn.svg"
                    className="home-logodora3"
                  />
                </div>
                <div className="home-frame-typography31">
                  <span className="home-text189 HeadingXSmall">Dora</span>
                </div>
              </div>
              <div className="home-frame-typography32">
                <span className="home-text190 BodyMedium">
                  Our partners at @gateway_eth keep on pushing the ZK world
                  forwards 🙌 You can now deploy a new Rollup using their
                  Rollup-as-a-Service Presto, leveraging @zksync stack
                </span>
              </div>
            </div>
          </div>
          <div className="home-frame1171275354">
            <div className="home-frame11712753352">
              <div className="home-frame117127533713">
                <img
                  alt="XWCAHUJP400x40019404"
                  src="/external/xwcahujp400x40019404-sqgb-200h.png"
                  className="home-xwcahujp400x4001"
                />
                <div className="home-frame-typography33">
                  <span className="home-text191 HeadingXSmall">
                    Sandeep Nailwal
                  </span>
                </div>
              </div>
              <div className="home-frame-typography34">
                <span className="home-text192 BodyMedium">
                  <span>Watchout for @gateway_eth team! Beasts!</span>
                  <br></br>
                  <span>🫡🫡 @mandrigin</span>
                </span>
              </div>
            </div>
            <div className="home-frame1171275340">
              <div className="home-frame117127533714">
                <div className="home-logognosis1">
                  <div className="home-logognosis2">
                    <img
                      alt="gnosisgnognologo1I919"
                      src="/external/gnosisgnognologo1i919-0dt.svg"
                      className="home-gnosisgnognologo1"
                    />
                  </div>
                </div>
                <div className="home-frame-typography35">
                  <span className="home-text196 HeadingXSmall">Gnosis</span>
                </div>
              </div>
              <div className="home-frame-typography36">
                <span className="home-text197 BodyMedium">
                  ... affordable and highly reliable RPC hosting services ...
                  during the Chiado testnet merge, processing the first
                  post-merge block. We look forward to this continued
                </span>
              </div>
            </div>
            <div className="home-frame1171275343">
              <div className="home-frame117127533715">
                <img
                  alt="Frame11712753449198"
                  src="/external/frame11712753449198-xyj.svg"
                  className="home-frame1171275344"
                />
                <div className="home-frame-typography37">
                  <span className="home-text198 HeadingXSmall">Coindesk</span>
                </div>
              </div>
              <div className="home-frame-typography38">
                <span className="home-text199 BodyMedium">
                  Gateway.fm has launched its Node-as-a-Service (NaaS) solution
                  to simplify the process of setting up and managing nodes
                </span>
              </div>
            </div>
          </div>
          <div className="home-frame1171275355">
            <div className="home-frame117127533716">
              <div className="home-frame117127533717">
                <img
                  alt="Vector9437"
                  src="/external/vector9437-sb1d.svg"
                  className="home-vector199"
                />
                <div className="home-frame-typography39">
                  <span className="home-text200 HeadingXSmall">Wirex</span>
                </div>
              </div>
              <div className="home-frame-typography40">
                <span className="home-text201 BodyMedium">
                  Wirex partners with Gateway.fm to elevate its ZK-powered WPay
                  decentralised payment network
                </span>
              </div>
            </div>
            <div className="home-frame1171275341">
              <div className="home-frame117127533718">
                <div className="home-logoarbitrum3">
                  <div className="home-logoarbitrum4">
                    <div className="home-arbitrumarblogo14">
                      <div className="home-layerx002015">
                        <div className="home-frame24055884772324">
                          <div className="home-group52">
                            <div className="home-group53">
                              <img
                                alt="VectorI918"
                                src="/external/vectori918-37io.svg"
                                className="home-vector200"
                              />
                              <div className="home-group54">
                                <div className="home-group55">
                                  <div className="home-group56">
                                    <img
                                      alt="VectorI918"
                                      src="/external/vectori918-2xng.svg"
                                      className="home-vector201"
                                    />
                                  </div>
                                  <div className="home-group57">
                                    <img
                                      alt="VectorI918"
                                      src="/external/vectori918-afc.svg"
                                      className="home-vector202"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="home-group58">
                                <img
                                  alt="VectorI918"
                                  src="/external/vectori918-ka6.svg"
                                  className="home-vector203"
                                />
                              </div>
                              <img
                                alt="VectorI918"
                                src="/external/vectori918-k3no.svg"
                                className="home-vector204"
                              />
                              <div className="home-group59">
                                <img
                                  alt="VectorI918"
                                  src="/external/vectori918-1mj5.svg"
                                  className="home-vector205"
                                />
                                <img
                                  alt="VectorI918"
                                  src="/external/vectori918-6t6xl.svg"
                                  className="home-vector206"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home-frame-typography41">
                  <span className="home-text202 HeadingXSmall">Arbitrum</span>
                </div>
              </div>
              <div className="home-frame-typography42">
                <span className="home-text203 BodyMedium">
                  The Arbitrum Orbit ecosystem, with 40+ chains already
                  announced, is getting another rollup as a service provider to
                  support teams looking to permissionelssly launch a chain!
                  Check out @gateway_eth 🪐
                </span>
              </div>
            </div>
            <div className="home-frame1171275342">
              <div className="home-frame117127533719">
                <div className="home-logohorizen2">
                  <div className="home-logohorizen3">
                    <div className="home-layer22">
                      <div className="home-layer14">
                        <img
                          alt="VectorI919"
                          src="/external/vectori919-5laf.svg"
                          className="home-vector207"
                        />
                        <img
                          alt="VectorI919"
                          src="/external/vectori919-2qyb.svg"
                          className="home-vector208"
                        />
                        <img
                          alt="VectorI919"
                          src="/external/vectori919-atvj.svg"
                          className="home-vector209"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home-frame-typography43">
                  <span className="home-text204 HeadingXSmall">
                    Horizen labs
                  </span>
                </div>
              </div>
              <div className="home-frame-typography44">
                <span className="home-text205 BodyMedium">
                  ...we are collaborating with Gateway FM to advance the modular
                  blockchain space with Zero-Knowledge Technology.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
