import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './card-description-compact.css'

const CardDescriptionCompact = (props) => {
  return (
    <div
      className={`card-description-compact-container ${props.rootClassName} `}
    >
      <div className="card-description-compact-frame1171275325">
        <div className="card-description-compact-frame1171275346">
          <div className="card-description-compact-frame-typography1">
            <span className="card-description-compact-text1 HeadingSmall">
              {props.heading ?? (
                <Fragment>
                  <span className="card-description-compact-text3">
                    Privacy
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="card-description-compact-frame-typography2">
            <span className="card-description-compact-text2 BodyMedium">
              {props.subtext ?? (
                <Fragment>
                  <span className="card-description-compact-text4">
                    Private like your bank account. Efficient like blockchain.
                    Zero Knowledge technology keeps data confidential
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

CardDescriptionCompact.defaultProps = {
  rootClassName: '',
  heading: undefined,
  subtext: undefined,
}

CardDescriptionCompact.propTypes = {
  rootClassName: PropTypes.string,
  heading: PropTypes.element,
  subtext: PropTypes.element,
}

export default CardDescriptionCompact
