import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import SelectorChain from '../components/selector-chain'
import CardPlanRPC from '../components/card-plan-rpc'
import CardPlanRPCCopy from '../components/card-plan-rpc-copy'
import CardNumber from '../components/card-number'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import Testimonial from '../components/testimonial'
import Footer from '../components/footer'
import './rpc.css'

const RPC = (props) => {
  return (
    <div className="rpc-container">
      <Helmet>
        <title>Gateway.fm | RPC (Remote Procedure Call) Solutions</title>
        <meta
          name="description"
          content="Explore Gateway.fm's RPC solutions for efficient communication between applications and blockchain networks. Enhance connectivity and streamline data exchange with our RPC services."
        />
        <meta
          property="og:title"
          content="Gateway.fm | RPC (Remote Procedure Call) Solutions"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's RPC solutions for efficient communication between applications and blockchain networks. Enhance connectivity and streamline data exchange with our RPC services."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/dd52e151-83dd-4026-a911-2792a89ef597?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name11"></Navigation>
      <div className="rpc-content">
        <div className="rpc-hero">
          <div className="rpc-description">
            <TitleProductHero text="RPC"></TitleProductHero>
            <SubtitleProductHero text="Accelerate Cross-System Communication: High-Performance RPC Solutions"></SubtitleProductHero>
            <USPCheckmark text="2x faster than competitors"></USPCheckmark>
            <USPCheckmark text="Best in class tech support"></USPCheckmark>
            <USPCheckmark text="Rollups &amp; validiums nodes"></USPCheckmark>
            <a
              href="https://admin.gateway.fm/"
              target="_blank"
              rel="noreferrer noopener"
              className="rpc-link"
            >
              <ButtonPrimarySubtitle
                supportive="1 click setup"
                className="rpc-component16"
              ></ButtonPrimarySubtitle>
            </a>
          </div>
          <img
            alt="image"
            src="/external/rpc-1200w.png"
            className="rpc-image"
          />
        </div>
        <div id="chains-container" className="rpc-chains-container">
          <SelectorChain chainSelectorId="gnosis"></SelectorChain>
          <SelectorChain
            logo="/external/logos/logo-stellar-200h.png"
            label="Stellar Soroban"
            chainSelectorId="stellar"
          ></SelectorChain>
          <SelectorChain
            logo="/external/logos/logo-polygon1-200h.png"
            label="Polygon zkEVM"
            chainSelectorId="polygon"
          ></SelectorChain>
          <SelectorChain
            logo="/external/logos/palm-logo-200w.png"
            label="Palm"
            chainSelectorId="palm"
          ></SelectorChain>
        </div>
        <div className="rpc-row1">
          <CardPlanRPC
            text3="up to 50 req/s (130M req/month)"
            buttonLink="https://admin.gateway.fm/"
            requestsID="requests"
            buttonlabel="1 click setup"
            rootClassName="card-plan-rp-croot-class-name"
          ></CardPlanRPC>
          <CardPlanRPCCopy
            text="PUBLIC RPC"
            text3="30 req/s (75M req/mon)"
          ></CardPlanRPCCopy>
        </div>
        <div className="rpc-row2">
          <CardPlanRPC
            main="Contact us"
            text="ENTERPRISE SOLUTION"
            text1="99.9% SLA"
            text2="Multiple regions"
            text3="Unlimited requests"
            buttonLink="/book-a-call"
            buttonLabel="Contact us"
            buttonlabel="Get a personalised offer"
            buttonSubtext="Get personalised setup"
            rootClassName="card-plan-rp-croot-class-name2"
          ></CardPlanRPC>
          <CardPlanRPC
            main="Contact us"
            text="DEDICATED CLUSTER"
            text1="99.9% SLA"
            text2="Our or your premise"
            text3="Any blockchain"
            buttonLink="/book-a-call"
            buttonLabel="Contact us"
            buttonlabel="Get a personalised offer"
            buttonSubtext="Get personalised setup"
            rootClassName="card-plan-rp-croot-class-name3"
          ></CardPlanRPC>
        </div>
        <div className="rpc-numbers">
          <CardNumber></CardNumber>
          <CardNumber
            text="99.9%"
            text1="Service Level Agreements"
          ></CardNumber>
          <CardNumber
            text="5"
            text1="Supported regions for all needs"
          ></CardNumber>
          <CardNumber text="5" text1="Supported blockchains"></CardNumber>
        </div>
        <SubtitleProductPromo></SubtitleProductPromo>
        <div className="rpc-testimonials">
          <Testimonial rootClassName="testimonialroot-class-name3"></Testimonial>
          <Testimonial
            name="Gnosis"
            text="... affordable and highly reliable RPC hosting services ... during the Chiado testnet merge, processing the first post-merge block. We look forward to this continued"
            position="Stefan George, CTO"
            rootClassName="testimonialroot-class-name4"
            logopolygonSrc="/external/image1inch1inchlogo26986-bbaj-200h.png"
          ></Testimonial>
          <Testimonial
            name="Goldsky"
            text="...we're used to seeing nodes latency increase overtime ... Gateway's node handled the load incredibly well, to the point we thought we did something wrong :)"
            position="@jefftyling"
            rootClassName="testimonialroot-class-name5"
            logopolygonSrc="/external/logo-goldsky-200h.png"
          ></Testimonial>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name3"></Footer>
    </div>
  )
}

export default RPC
